<template>
	<div class="cs-block cs-footer-menu-block">
		<div class="cs-base-block">
			<CSFooterMenu
				:logo="data.logo || ''"
				:contacts="data.contacts || ''"
				:icons="data.icons && ((Array.isArray(data.icons) && data.icons.length) || (typeof data.icons == 'object' && Object.keys(data.icons).length)) ? data.icons : {}"
				:copyright="data.copyright || ''"
				:menu="menu"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex"

import {computed} from '../libs/common-fn';

export default {
	components: {
		CSFooterMenu: () => import("../components/CSFooterMenu.vue")
	},
	data() {
		return {
			menu: []
		}
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: computed('FooterMenu'),
	methods: {
		...mapActions("menu", ["retrieve"]),
	},
	mounted() {
		this.retrieve({ id: this.data.menu, l1: 4, l2: 3, l3: 6 }).then(menu => {
			this.menu = Object.values(menu)
		})
	},
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
.cs-block.cs-footer-menu-block {
	background-color: #142237;

	::v-deep {
		.footer-menu {
			background-color: #142237;
		}

		.contacts{
			color:white;
		}

		.cs-fm-m1-title {
			span {
				color: #c0c0c0;
			}

			a {
				color: #ddd;
				&:hover {
					color: #c0c0c0;
				}
			}
		}

		.cs-fm-m2-title {
			span {
				color: #c0c0c0;
			}

			a {
				color: white;
				&:hover {
					color: #c0c0c0;
				}
			}
		}

		.cs-fm-m3-title {
			span {
				color: #c0c0c0;
			}
			
			a {
				color: white;
				&:hover {
					color: #c0c0c0;
				}
			}
		}
		
		.copyright {
			color: #ddd;
		}
	}
}
</style>
